.workOrderItems_font {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #344054;
}
.workOrderItems_thead_color {
  background: #f9fafb;
  // border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #eaecf0;
}
