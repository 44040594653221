// .btn-light{width: 102px;}
.work-order-items-edit {
  tr {
    td {
      select.form-control {
        padding: 0 !important;
      }
      select[disabled] {
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }
}
.allocateTargetErrorText {
  color: #ff0000;
  margin-bottom: 0px;
}

.editsavedmodalheader {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.editsavedmodalinput {
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  border: solid 1px #d0d5dd;
  color: #344054;
}
.editsavedmodalreason {
  font-family: Inter;
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.editsavedmodalbutton {
  font-family: Inter;
  border: 1px solid #155eef;
  border-radius: 8px;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  background-color: #155eef;
  color: #ffffff;
}
.editsavedmodalbutton,
.closewomodalbutton:hover {
  color: #ffffff;
  transform: scale(1.05);
}

.closewomodalbutton {
  font-family: Inter;
  border: solid 1px #f04438;
  background: #f04438;
  border-radius: 8px;
  padding: 10px 14px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.workOrderItems_font {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #344054;
}
.workOrderItems_thead_color {
  background: #f9fafb;
  // border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #eaecf0;
}
