@import '../../scss/variables';

.work-order-filter {
  position: relative;
  z-index: 999;
}

.draft-work-order-text {
  font-size: 15px;
  font-weight: bold;
  margin-left: 14px;
}

.column-filter-input {
  height: 26px;
  padding: 3px 5px 3px 5px;
  background: #ffffff !important;
  border: 0;
  border-radius: 6px;
}

.column-filter-input-icon {
  height: 26px;
  border: 0;
  background: #ffffff !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.table .thead-light .thead-main th {
  background-color: #f9fafb !important;
}

.table-container {
  position: relative;
  overflow-x: auto;
  height: 500px;
}

.table-container-overflow {
  overflow-x: auto;
  position: relative;
}

.sticky-table {
  border: 1px solid #eaecf0;
  width: 155%;

  thead th {
    border-bottom: 0;
  }
}

.sticky {
  position: sticky !important;
  background: white;
  z-index: 10;
}

th.sticky,
td.sticky,
td .text-right {
  width: 100px;
}

th:first-child.sticky,
td:first-child.sticky {
  left: 0;
  width: 50px;
}

th:nth-child(2).sticky,
td:nth-child(2).sticky {
  left: 50px;
  border-right: 2px solid #eaecf0;
  width: 200px;
}

th:last-child.sticky,
td:last-child.sticky {
  right: 0;
  border-left: 2px solid #eaecf0;
  width: 150px;
}

td:last-child.sticky {
  z-index: 0;
}

.work-order-wrapper {
  display: flex;
  gap: 1.5rem;
  .work-order-card {
    flex: 1;
  }
}

.work-order-summary {
  background-color: #f2f4f7;

  h6 {
    font-size: 14px;
    font-weight: 500;
    color: #475467;
  }

  h4 {
    font-weight: 600 !important;
    color: #344054;
  }

  .brand-count {
    width: max-content;
    height: 22px;
    border-radius: 16px;
    border: 1px solid #eaecf0;
    padding: 4px 8px;
    background-color: #f9fafb;
    font-size: 12px;
  }
}

.react-select.column-filter-dropdown .select__control {
  height: 26px;
  border: 0;
  min-height: 26px;
  background: #ffffff;
}

.react-select.column-filter-dropdown .select__control .select__placeholder,
.react-select.column-filter-dropdown .select__control .select__single-value {
  top: 44%;
  font-size: 12px;
}

.react-select.column-filter-dropdown .select__control .select__indicator {
  padding-top: 2px;
}

.react-select.column-filter-dropdown .select__control .select__indicator-separator {
  width: 0 !important;
}

.react-select.column-filter-dropdown .select__menu {
  z-index: 999 !important;
  width: auto !important;
}

.active-card {
  border: 1px solid #175cd3;
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  border-top-color: #000 !important;
}

.brand-work_order-cards {
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-right: 1px solid #d0d5dd;
}
#last-card {
  border-right: none;
}

.brand-wo-card-container {
  border: 1px solid #eaecf0;
  padding: 10px 12px;
  margin: 0.5rem;
  width: Fill (1, 292px);
}
